import localFont from 'next/font/local';
import { Bai_Jamjuree, Onest } from 'next/font/google';

const formula = localFont({
  variable: '--font-formula',
  src: [
    {
      path: './../../public/font/PPFormula-Extrabold.woff2',
      weight: '800',
    },
    {
      path: './../../public/font/PPFormula-SemiExtendedBold.woff2',
      weight: '700',
    },
    {
      path: './../../public/font/PPFormula-Regular.woff2',
      weight: '400',
    },
  ],
  display: 'swap',
});

const robotoFlex = localFont({
  variable: '--font-roboto-flex',
  src: [
    {
      path: './../../public/font/RobotoFlex-VariableFont_GRAD,XOPQ,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.woff2',
      weight: '800',
    },
    {
      path: './../../public/font/RobotoFlex-VariableFont_GRAD,XOPQ,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.woff2',
      weight: '700',
    },
    {
      path: './../../public/font/RobotoFlex-VariableFont_GRAD,XOPQ,XTRA,YOPQ,YTAS,YTDE,YTFI,YTLC,YTUC,opsz,slnt,wdth,wght.woff2',
      weight: '400',
    },
  ],
  display: 'swap',
});

const jamjuree = Bai_Jamjuree({
  variable: '--font-jamjuree',
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
});

const onest = Onest({
  variable: '--font-onest',
  weight: ['400', '500', '600', '700'],
  subsets: ['cyrillic'],
  display: 'swap',
});

const fonts = {
  formula,
  robotoFlex,
  jamjuree,
  onest,
};

export default fonts;
