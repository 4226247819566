import fonts from '../styles/fonts';
import 'tailwindcss/tailwind.css';
import '../styles/globals.css';
import '../../public/js/intl-tel-input/css/intlTelInput.min.css';
import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
import { isMobile } from '@/utils/isMobile';
import StyledComponentsRegistry from '../registry';
import { useUtmAttributes } from '@/hooks/useUtmAttributes';
import NextProgress from 'nextjs-progressbar';
import { COLORS } from '@/constants/colors';
import { useConversationTracking } from '@/hooks/useConversationTracking';
import dynamic from 'next/dynamic';

const QuidgetScript = dynamic(() => import('@/components/QuidgetScript/QuidgetScript'), {
  ssr: false,
});

function MyApp({ Component, pageProps }: AppProps) {
  useUtmAttributes();

  useEffect(() => {
    if (isMobile()) {
      document.body.classList.add('mobile');
      document.body.classList.remove('desktop');
    } else {
      document.body.classList.remove('mobile');
      document.body.classList.add('desktop');
    }
  }, []);

  useConversationTracking();

  return (
    <div
      className={`m-0 p-0 ${fonts.formula.variable} ${fonts.jamjuree.variable} ${fonts.onest.variable} ${fonts.robotoFlex.variable}`}
    >
      <StyledComponentsRegistry>
        <NextProgress
          color={COLORS.GREEN}
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow
        />
        <Component {...pageProps} />
        <QuidgetScript hidden={pageProps?.quidgetHidden} isMobile={isMobile} />
      </StyledComponentsRegistry>
    </div>
  );
}

export default MyApp;
